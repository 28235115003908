import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import clsx from 'clsx';
import InputGroup from '@/components/InputGroup';
import { trpc } from '@/lib/trpc';
import { createNotification } from '@/components/Toast';
import EditIcon from '@/icons/pencil.svg';
import SaveIcon from '@/icons/tick.svg';
import CloseIcon from '@/icons/close.svg';
import LoadingSpinner from '@/components/LoadingSpinner';

const schema = z.object({
  customer: z.string().optional(),
  site: z.string(),
  siteName: z.string(),
});

type EditSiteData = z.infer<typeof schema>;

interface SiteNameProps {
  customer?: string;
  originalName: string;
  siteId: string;
  canEdit: boolean;
}

export default function SiteName({
  customer,
  originalName,
  siteId,
  canEdit,
}: SiteNameProps) {
  const [edit, setEdit] = useState(false);
  const [isError, setIsError] = useState(false);
  const utils = trpc.useUtils();

  const { mutate, isLoading } = trpc.sites.updateSite.useMutation();

  const { register, handleSubmit, formState, setFocus, watch } =
    useForm<EditSiteData>({
      defaultValues: {
        site: siteId,
        siteName: originalName,
      },
      resolver: zodResolver(schema),
    });

  const siteName = watch('siteName');

  const onSubmit = (values: EditSiteData) => {
    setIsError(false);

    if (canEdit) {
      mutate(
        {
          customer,
          siteId,
          ...values,
        },
        {
          onSuccess: (data) => {
            if (data?.id) {
              createNotification({
                message: <>Site name successfully updated</>,
                type: 'success',
              });
              utils.sites.get.invalidate({ id: siteId });
              utils.sites.getAll.invalidate();
              setEdit(false);
            } else {
              createNotification({
                message: (
                  <>The Site name could not be updated, please try again.</>
                ),
                type: 'warning',
              });
            }
          },
          onError: () => {
            setIsError(true);
            createNotification({
              message: (
                <>The Site name could not be updated, please try again.</>
              ),
              type: 'warning',
            });
          },
        }
      );
    }
  };

  return (
    <div className="site-name">
      <form
        className={clsx(
          'site-name__form',
          edit === true && 'site-name__form--editing'
        )}
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputGroup
          wrapperClassName="site-name__input-group"
          className="font-size-60 navigation__heading site-name__input"
          isError={!!(formState?.errors?.siteName?.message || isError)}
          readOnly={edit === false}
          disabled={formState?.isLoading || isLoading}
          {...register('siteName')}
          style={{ width: `${Math.min(Math.max(siteName.length, 2), 50)}ch` }}
        />

        {canEdit && edit === false && (
          <button
            className="site-name__edit"
            onClick={() => {
              setEdit(true);
              setFocus('siteName');
            }}
            type="button"
          >
            <EditIcon className="site-name__edit-icon" />
            <span className="site-name__tooltip">Edit</span>
          </button>
        )}

        {canEdit && (
          <div
            className={clsx(
              'site-name__buttons',
              edit === true && 'site-name__buttons--editing'
            )}
          >
            <button
              aria-label="Edit site name"
              className="site-name__save"
              disabled={isLoading || formState.isSubmitting}
              type="submit"
              title="Save"
            >
              {formState.isSubmitting || isLoading ? (
                <LoadingSpinner className="site-name__spinner" size="16" />
              ) : (
                <SaveIcon className="site-name__save-icon" />
              )}
            </button>
            <button
              aria-label="Cancel"
              className="site-name__close"
              disabled={isLoading || formState.isSubmitting}
              type="button"
              title="Cancel"
              onClick={() => {
                setEdit(false);
              }}
            >
              <CloseIcon className="site-name__close-icon" />
            </button>
          </div>
        )}
      </form>
    </div>
  );
}
