export const INCIDENT = {
  PLURAL: 'faults',
  SINGULAR: 'fault',
};

export const capitalise = (word: string) => {
  return word.split(' ')
  .map(
    (_word) => _word.charAt(0).toUpperCase() + _word.slice(1).toLowerCase()
  ).join(' ');
};
