import { useSession, signIn } from 'next-auth/react';
import Button from '@/components/Button';
import ExclamationMark from '@/icons/exclamation.svg';

export default function ImpersonationBanner() {
  const session = useSession();

  const originalUserId = session?.data?.user.impersonatedBy;
  const redirectTarget = session?.data?.user.exitImpersonationTo;

  const exitImpersonation = () => {
    if (originalUserId) {
      signIn('impersonation', {
        user_id: originalUserId,
        redirect: false,
      }).then((res) => {
        if (res?.ok)
          window.location.href = redirectTarget
            ? `/organisations/${redirectTarget}`
            : '/';
      });
    }
  };

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!originalUserId) return <></>;

  return (
    <div className="impersonation-banner">
      <p className="impersonation-banner__text">
        <ExclamationMark className="impersonation-banner__icon" /> You are
        currently viewing as {session.data?.user.name}
      </p>
      <Button
        type="button"
        variant="primary"
        size="small"
        onClick={exitImpersonation}
        className="impersonation-banner__button"
      >
        Stop impersonating
      </Button>
    </div>
  );
}
