import { useState, useEffect } from 'react';
import { useSession, signOut } from 'next-auth/react';
import * as Popover from '@radix-ui/react-popover';
import clsx from 'clsx';
import Link from 'next/link';
import Arrow from '@/icons/down-arrow.svg';
import Avatar from '@/components/Avatar';
import SiteName from '@/components/sites/SiteName';
import { can } from '@/lib/permissions';

interface NavProps {
  customer?: string;
  pageTitle?: string;
  siteId?: string;
}

export default function Navigation({ customer, pageTitle, siteId }: NavProps) {
  const [showMenu, setShowMenu] = useState(false);
  const [greeting, setGreeting] = useState('');
  const session = useSession();

  useEffect(() => {
    if (session.data?.user.name) {
      const currentTime = new Date();
      const timeOfDay = currentTime.getHours();
      let message = 'Good morning, ';

      if (timeOfDay >= 12 && timeOfDay < 17) {
        message = 'Good afternoon, ';
      } else if (timeOfDay >= 17) {
        message = 'Good evening, ';
      }

      setGreeting(`${message}${session.data.user.name}`);
    }
  }, [session.data?.user.name]);

  const toggleUserMenu = () => {
    setShowMenu(!showMenu);
  };

  const logOutUser = async () => {
    await signOut().then(() => setShowMenu(false));
  };

  return (
    <div className="navigation">
      <div className="container navigation__container">
        <div className="row navigation__row">
          <div className="col-7 navigation__col navigation__col--left">
            {siteId && pageTitle ? (
              <SiteName
                canEdit={
                  !!(
                    session?.data?.user?.role &&
                    can(session.data.user.role, 'edit_site')
                  )
                }
                customer={customer}
                siteId={siteId}
                originalName={pageTitle}
              />
            ) : (
              <h1 className="font-size-60 navigation__heading">
                {pageTitle || greeting}
              </h1>
            )}
          </div>
          <div className="col-5 navigation__col navigation__col--right">
            <button
              className="navigation__menu"
              onClick={() => toggleUserMenu()}
              title="My account"
              type="button"
            >
              <Avatar
                className="navigation__avatar"
                name={session?.data?.user?.name ?? ''}
                size="medium"
              />
              <p className="navigation__label">
                {session?.data?.user?.name ?? ''}
              </p>
              <div className="navigation__arrow-wrap">
                <Arrow
                  className={clsx(
                    'navigation__arrow',
                    showMenu && 'navigation__arrow--active'
                  )}
                />
              </div>
            </button>
            <Popover.Root
              open={showMenu}
              onOpenChange={(newState) => setShowMenu(newState)}
            >
              <Popover.Anchor />
              <Popover.Content className="navigation__dropdown" align="end">
                <div className="navigation__top">
                  <Avatar
                    className="navigation__menu-avatar"
                    name={session?.data?.user?.name ?? ''}
                    size="medium"
                  />

                  <p className="font-size-30 navigation__user">
                    {session?.data?.user.name ?? ''}
                  </p>
                  <p className="font-size-30 navigation__email">
                    {session?.data?.user.email ?? ''}
                  </p>
                </div>
                <ul className="navigation__list">
                  <li className="navigation__item">
                    <Link className="navigation__link" href="/settings">
                      Settings
                    </Link>
                  </li>
                  <li className="navigation__item">
                    <button
                      className="navigation__link"
                      onClick={() => logOutUser()}
                      type="button"
                    >
                      Log out
                    </button>
                  </li>
                </ul>
              </Popover.Content>
            </Popover.Root>
          </div>
        </div>
      </div>
    </div>
  );
}
