import clsx from 'clsx';
import { Toast, toast } from 'react-hot-toast';
import HelpText from '@/components/HelpText';

interface ToastProps extends React.ComponentProps<typeof HelpText> {
  toastObj: Toast;
}

interface CreateNotificationOpts {
  message: React.ReactNode;
  type: React.ComponentProps<typeof HelpText>['type'];
}

export function createNotification({ message, type }: CreateNotificationOpts) {
  toast.custom((t) => (
    <ToastComponent toastObj={t} type={type}>
      {message}
    </ToastComponent>
  ));
}

export default function ToastComponent({ toastObj, ...props }: ToastProps) {
  return (
    <div
      className={clsx(
        'toast',
        toastObj.visible && 'toast--visible',
        !toastObj.visible && 'toast--hidden',
        props.type && `toast--${props.type}`
      )}
    >
      <HelpText {...props} />
    </div>
  );
}
