import clsx from 'clsx';

export interface LoadingProps {
  alignment?: 'bottom' | 'right';
  className?: string;
  colour?: 'black' | 'grey' | 'white';
  display?: 'flex' | 'inline-block';
  showText?: boolean;
  size?: '14' | '16' | '20' | '24' | '32' | '48' | '72';
}

export default function LoadingSpinner({
  className,
  showText,
  alignment = 'bottom',
  colour = 'grey',
  display = 'flex',
  size = '48',
}: LoadingProps) {
  return (
    <div
      className={clsx(
        'loading-spinner',
        display && `loading-spinner--${display}`,
        className && className,
        alignment && `loading-spinner--${alignment}`
      )}
    >
      <div
        className={clsx(
          'loading-spinner__circle',
          colour && `loading-spinner__circle--${colour}`,
          size && `loading-spinner__circle--${size}`
        )}
      >
        <div
          className={clsx(
            'loading-spinner__inner',
            size && `loading-spinner__inner--${size}`,
            colour && `loading-spinner__inner--${colour}`
          )}
        />
        <div
          className={clsx(
            'loading-spinner__inner',
            size && `loading-spinner__inner--${size}`,
            colour && `loading-spinner__inner--${colour}`
          )}
        />
        <div
          className={clsx(
            'loading-spinner__inner',
            size && `loading-spinner__inner--${size}`,
            colour && `loading-spinner__inner--${colour}`
          )}
        />
        <div
          className={clsx(
            'loading-spinner__inner',
            size && `loading-spinner__inner--${size}`,
            colour && `loading-spinner__inner--${colour}`
          )}
        />
      </div>
      {showText && (
        <p
          className={clsx(
            'loading-spinner__text',
            alignment && `loading-spinner__text--${alignment}`,
            colour && `loading-spinner__text--${colour}`,
            size && `loading-spinner__text--${size}`
          )}
        >
          Loading...
        </p>
      )}
    </div>
  );
}
