import clsx from 'clsx';
import Image from 'next/image';

interface AvatarProps {
  className?: string;
  image?: string;
  name?: string;
  size?: 'xs' | 'small' | 'medium' | 'large';
}

export default function Avatar({
  className,
  image,
  name,
  size = 'small',
}: AvatarProps) {
  // Fallback function to generate a users' initials
  const generateAvatarInitials = (fullName?: string) => {
    if (!fullName) {
      return '';
    }
    const splitName = fullName.split(' ');
    const initials = [splitName[0][0], splitName[1]?.[0]];
    const filteredInitials = initials.filter((initial) => !!initial);

    return filteredInitials.join('');
  };

  const initials = generateAvatarInitials(name ?? '');

  if (!image && !name) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <div
      className={clsx(
        'avatar',
        size && `avatar--${size}`,
        className && className
      )}
    >
      {image ? (
        <Image
          className="avatar__image"
          width={64}
          height={64}
          src={image}
          alt="Profile picture"
        />
      ) : (
        <span
          className={clsx(
            'avatar__initials',
            size && `avatar__initials--${size}`
          )}
        >
          {initials}
        </span>
      )}
    </div>
  );
}
