import Navigation from '@/components/Navigation';
import Sidebar from '@/components/Sidebar';
import ImpersonationBanner from '@/components/ImpersonationBanner';

interface LayoutProps {
  children: React.ReactNode;
  customer?: string;
  heading?: string;
  siteId?: string;
}

export default function Layout({
  children,
  customer,
  heading,
  siteId,
}: LayoutProps) {
  return (
    <div className="layout">
      <Sidebar />
      <Navigation
        customer={customer}
        pageTitle={heading ?? ''}
        siteId={siteId}
      />
      <div className="layout__content">{children}</div>
      <ImpersonationBanner />
    </div>
  );
}
