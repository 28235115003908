import { type User } from '@prisma/client';
import { UserRole } from '@/lib/auth';

const possiblePermissions = [
  'read_all_incidents',
  'read_own_incidents',
  'read_all_requests',
  'read_own_request',
  'create_own_incident',
  'create_any_incident',
  'create_own_action',
  'create_any_action',
  'edit_request',
  'read_system_info',
  'read_platform_info',
  'read_all_customers',
  'read_own_customers',
  'create_customers',
  'edit_customers',
  'read_system_errors',
  'read_user_errors',
  'read_all_sites',
  'read_own_sites',
  'read_own_devices',
  'read_all_devices',
  'create_own_request',
  'create_any_request',
  'create_own_users',
  'create_any_users',
  'create_tiviti_users',
  'impersonate_any_user',
  'impersonate_org_role_user',
  'update_all_org_photos',
  'update_own_org_photo',
  'edit_supplier_reference',
  'view_supplier_reference',
  'edit_site',
  'read_own_order',
  'edit_own_order',
  'read_all_orders',
  'edit_all_orders',
  'create_technical_data_request',
  'create_technical_users',
] as const;

export type Permission = (typeof possiblePermissions)[number];

export const permissions: Map<UserRole, Array<Permission>> = new Map([
  [
    UserRole.TIVITI_ADMIN,
    [
      'read_system_errors',
      'read_all_customers',
      'read_all_requests',
      'read_all_incidents',
      'create_customers',
      'edit_customers',
      'create_any_incident',
      'create_own_incident',
      'edit_request',
      'create_any_request',
      'create_own_request',
      'read_user_errors',
      'read_system_info',
      'read_platform_info',
      'read_all_sites',
      'read_all_devices',
      'create_any_users',
      'impersonate_any_user',
      'create_tiviti_users',
      'update_all_org_photos',
      'edit_supplier_reference',
      'view_supplier_reference',
      'create_any_action',
      'edit_site',
      'read_all_orders',
      'edit_all_orders',
      'create_technical_data_request',
    ],
  ],
  [
    UserRole.TIVITI_DELIVERY,
    [
      'read_all_customers',
      'read_all_orders',
      'create_technical_data_request',
      'create_technical_users',
    ],
  ],
  [UserRole.TIVITI_FIELD_ENGINEER, ['read_all_orders', 'edit_all_orders']],
  [
    UserRole.TIVITI_SUPPORT,
    [
      'read_all_customers',
      'read_all_requests',
      'read_all_incidents',
      'create_any_incident',
      'create_own_incident',
      'edit_request',
      'create_any_request',
      'create_own_request',
      'read_user_errors',
      'read_system_info',
      'read_platform_info',
      'read_all_sites',
      'read_all_devices',
      'impersonate_org_role_user',
      'create_tiviti_users',
      'update_all_org_photos',
      'edit_supplier_reference',
      'view_supplier_reference',
      'create_any_action',
      'edit_site',
    ],
  ],
  [
    UserRole.TIVITI_SUPPORT_MANAGER,
    [
      'read_all_customers',
      'read_all_requests',
      'read_all_incidents',
      'create_any_incident',
      'create_own_incident',
      'edit_request',
      'create_any_request',
      'create_own_request',
      'read_user_errors',
      'read_system_info',
      'read_platform_info',
      'read_all_sites',
      'read_all_devices',
      'impersonate_org_role_user',
      'create_tiviti_users',
      'update_all_org_photos',
      'edit_supplier_reference',
      'view_supplier_reference',
      'create_any_action',
      'edit_site',
    ],
  ],
  [
    UserRole.TIVITI_USER,
    [
      'read_platform_info',
      'read_all_incidents',
      'read_all_requests',
      'read_all_sites',
      'read_all_devices',
      'read_all_customers',
      'view_supplier_reference',
    ],
  ],
  [
    UserRole.ORG_ADMIN,
    [
      'read_own_customers',
      'read_own_devices',
      'read_own_incidents',
      'read_own_request',
      'read_own_sites',
      'create_own_incident',
      'create_own_request',
      'update_own_org_photo',
      'create_own_users',
      'create_own_action',
      'read_own_order',
      'edit_own_order',
    ],
  ],
  [
    UserRole.ORG_SUPPORT,
    [
      'read_own_customers',
      'read_own_devices',
      'read_own_incidents',
      'read_own_request',
      'read_own_sites',
      'create_own_incident',
      'create_own_request',
      'create_own_action',
    ],
  ],
  [UserRole.ORG_TECHNICAL_CONTACT, ['read_own_order', 'edit_own_order']],
  [
    UserRole.ORG_USER,
    [
      'read_own_devices',
      'read_own_incidents',
      'read_own_request',
      'read_own_sites',
    ],
  ],
]);

export const can = (
  role: User['role'],
  permission: Permission | Array<Permission>
) => {
  const assumedRole = getRole(role);
  const permissionSet = [...(permissions.get(assumedRole)?.values() ?? [])];

  if (Array.isArray(permission)) {
    const matchingPermissions = permission.filter((permToCheck) =>
      permissionSet.includes(permToCheck)
    );

    return matchingPermissions.length > 0;
  }

  return permissionSet.includes(permission);
};

const getRole = (role: User['role']) => role as UserRole;
